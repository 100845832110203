const databits = [
  {
    zona: 'Norte - Av. Eloy Alfaro',
    name: 'PATERWIL IMPORT S.C.',
    contacto: 'PATERSON MIÑO',
    email: 'pmino@unilimpio.com',
    movil: '0999951615',
    convencional: '022805492'
    
  },
  {
    zona: 'Norte - Av. Occidental',
    name: '',
    contacto: 'DIMITRI CISNEROS',
    email: 'dcisneros@unilimpio.com',
    movil: '0984589666',
    convencional: ''
    
  },
  {
    zona: 'Centro - Norte',
    name: 'MOREIRA MENDOZA WILMER HOMERO',
    contacto: 'Wilmer Moreira',
    email: 'wmoreira@unilimpio.com',
    movil: '0979708017',
    convencional: '023210455'
    
  },

  {
    zona: 'Sector El Bosque',
    name: 'REILPROS',
    contacto: 'Marcela Garzón',
    email: '',
    movil: '0989763341',
    convencional: ''
    
  },
  {
    zona: 'Valles Norte (Cumbayá, Tumbaco, Pifo, Tababela)',
    name: 'LIMPIECITO',
    contacto: 'Galo Ochoa',
    email: 'gochoa@unilimpio.com',
    movil: '0998006661',
    convencional: ''
    
  },
  {
    zona: 'Sur',
    name: '',
    contacto: 'Xavier Sotelo',
    email: 'xavier.sotelo@unilimpio.com',
    movil: '0986683230',
    convencional: ''
    
  },
  {
    zona: 'Valle de los chillos (sangolquí, amaguaña)',
    name: '',
    contacto: 'Victor Lima',
    email: 'vlima@unilimpio.com',
    movil: '0990747812',
    convencional: ''
    
  },
  {
    zona: 'Calderon, Carapungo, Mitad del Mundo',
    name: 'ASPRO DISTRIBUCIONES',
    contacto: 'ESTEFANÍA SOLÍS / ESTEBAN CARRIÓN ',
    email: 'cpfdistribuciones@unilimpio.com',
    movil: '0999021896, 0999273399',
    convencional: ''
    
  },
  {
    zona: 'Puembo, Pifo, Tababela',
    name: 'ECUADISTRIBUCIONES',
    contacto: 'Sandra Lamiña',
    email: 'ecuadistribuciones@yahoo.com',
    movil: '0984055123',
    convencional: '02730767'
    
  },

];
export default databits;
