const databits = [
  {
    zona: 'Toda la ciudad de Guayaquil',
    name: 'BIOLIMPIO',
    contacto: 'CESAR CELLERI',
    email: '',
    movil: '0992966073',
    convencional: ''
    
  },
  {
    zona: 'Toda la ciudad de Guayaquil',
    name: 'TOTALBOX',
    contacto: 'REBECA DIAZ',
    email: '',
    movil: '0984203282',
    convencional: ''
    
  },
  {
    zona: 'Toda la ciudad de Guayaquil',
    name: 'VEBRACLEAN',
    contacto: 'ING. ALEX VEGA',
    email: 'vebraclean@hotmail.com',
    movil: '0991507929',
    convencional: ''
    
  },
  {
    zona: 'Toda la ciudad de Guayaquil',
    name: 'REILPROS',
    contacto: 'David Benalcazar',
    email: 'ventasgye.biotower@gmail.com',
    movil: '0998244133',
    convencional: ''
    
  },
  


];
export default databits;
