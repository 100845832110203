const databits = [
  {
    zona: '',
    name: '',
    contacto: 'JESSICA GUTIERREZ',
    email: '',
    movil: '0991214051',
    convencional: '072939704'
    
  },
 


];
export default databits;
