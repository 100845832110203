const databits = [
  {
    zona: '',
    name: '',
    contacto: 'JAVIER ROMERO',
    email: '',
    movil: '0994655557',
    convencional: ''
    
  },
 


];
export default databits;
