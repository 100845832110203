const databits = [
  {
    name: 'Matriz',
    direccion:
      'De los eucaliptos E1-140 y psje. Juncos.',
    sector: 'Sector Parque de los recuerdos',
    codigoPostal: '170307',
    ciudad: 'Quito, Ecuador', 
    email: 'info@unilimpio.com',
    telefonos: 'PBX: +593 2 247-9923'
    
  },
  {
    name: 'Centro de distribución Costa',
    direccion:
      'Doctor Honorato Vasquez y calle Laureles',
    sector: 'Km. 9 1/2 via Daule, Parque industrial Inmaconsa',
    codigoPostal: '',
    ciudad: 'Guayaquil, Ecuador', 
    email: 'srodriguez@unilimpio.com',
    telefonos: '1800 UNILIM (864546)'
  
  },
  {
    name: 'Centro de distribución Austro',
    direccion:
      'Octavio Chacon 3100',
    sector: 'Parque Industrial',
    codigoPostal: '',
    ciudad: 'Cuenca, Ecuador', 
    email: 'jpdavila@unilimpio.com',
    telefonos: '0994476780'
  
  }


];
export default databits;
