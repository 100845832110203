const databits = [
  {
    zona: 'Austro',
    name: 'UNILIMPIO SUR',
    contacto: 'ING. JUAN PABLO DAVILA',
    email: 'jpdavila@unilimpio.com',
    movil: '0994476780',
    convencional: ''
    
  },
 


];
export default databits;
