const databits = [
  {
    zona: '',
    name: 'AQUAFIT',
    contacto: 'ING. MARIBEL HERRERA',
    email: '',
    movil: '0967532492',
    convencional: ''
    
  },
 


];
export default databits;
